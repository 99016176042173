.ant-menu>.ant-menu-item:hover,
.ant-menu>.ant-menu-submenu:hover,
.ant-menu>.ant-menu-item-active,
.ant-menu>.ant-menu-submenu-active,
.ant-menu>.ant-menu-item-open,
.ant-menu>.ant-menu-submenu-open,
.ant-menu>.ant-menu-item-selected,
.ant-menu>.ant-menu-submenu-selected {
    color: white;
    border: 1px solid #58B638;
    background-color: transparent;
}

.ant-menu>.ant-menu-item:hover,
.ant-menu>.ant-menu-submenu:hover,
.ant-menu>.ant-menu-submenu-selected:hover {
    color: white !important;
}

.h-inherit {
    height: inherit;
}

.flexx {
    flex-shrink: 0;
}

.ant-card-body{
    width: 100%;
}